import React from "react";
import SEO from "../components/seo";
import BlogContainer from "../containers/blog/blog-grid";
import Header from "../layouts/header/index";
import Layout from "../layouts/index";

const Blog = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Zoran Ilić - Bio" />
                <div className="wrapper home-default-wrapper">
                    <Header classOption="hb-border" />
                    <div className="main-content">
                        <BlogContainer />
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default Blog;
