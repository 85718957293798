import React from "react";
import BlogData from "../../../data/blog.json";
import useMasonry from "../../../hooks/use-masonry";
import { Link } from "react-router-dom";

const BlogContainer = () => {
    const { categories } = useMasonry(
        BlogData,
        ".masonryGrid",
        ".masonry-item",
        ".blog-filter-menu",
        ".blog-filter-menu button"
    );
    return (
        <div className="blog-area blog-masonry-area">
            <div className="container" style={{ marginTop: '50px'}}>
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-4 resizer"></div>
                    <div className="messonry-button text-center mb-50"><Link to='/'>Gallery</Link></div>
                    <div>
    
                        <div className="text-center" data-aos="fade-up">
                        Rodjen 24. aprila 1963 u Peci (Kosovo i Metohija).
Zivi u Srbiji u mestu Ruma. 
Zavrsio srednju skolu unutrasnjih poslova ,,Pane Djukic'' u Sremskoj Kamenici.
Studirao P.P.Z. na visoj tehnickoj skoli u Novom Sadu.


Ljubav prema umetnosti je pocela 1989. godine kada je postao pomocni umetnik (1989-1992)  Cece Sabahudina u Berlinu
Izlagao na slovodnoj Berlinskoj umetnickoj izlozbi umetnika,Berlin,1990-1991 godine
U Srbiji je izlagao u Rumskom muzeju 2006. godine,pod nazovom ,,Crtezi u boji''
Na izlozbi su izlozena 133 crteza.
Ucestovao na likovnoj koloniji 2016 godine u Rumi kao domoacin.



-Na fotografijama su slike koje je oslikao u periodu od 1999-2016 godine 
-Postavljena su 100 slika dimenzija 29x20,5cm (A4 format).
                            </div>

                            <div className="text-center" data-aos="fade-up " style={{ marginTop: '40px'}}>
                            Born 24th April 1963. In Pec (Kosovo and Metohija)
Currently lives in Serbia, small town Ruma.
He finished highschool of Internal Affairs "Pane Djukic" in Sremska Kamenica.
He studied Fire Protection in college in Novi Sad.

His passion for art started in 1989. when he became assistant artist to Ceca Sabahudin in Berlin. Shortly after dabbling into arts he had his first exhibition in Berlin (1990-1991). Later he had another exhibition "Art in color" in Serbia in his now hometown Ruma in Museum in 2006. where he exhibited 133 paintings. He also participated in Arts Colony in 2016. in Ruma as host.

-On photos are paintings he painted in period from 1999. to 2016.
-Dimensions of 100 shown pictures are 29x20,5cm (A4)
                            </div>

                        <div className="text-center" data-aos="fade-up" style={{ marginTop: '25px'}}>
                            <h4 className="title">Kontakt</h4>
                            <p style={{ margin: '10px'}}>
                                iliczoran.loke@gmail.com
                            </p>
                            <p style={{ margin: '10px'}}>
                                0643388806
                            </p>
                            <p style={{ margin: '10px'}}>
                                Zoran Ilic "Loke"
                            </p>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogContainer;
