import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const PortfolioFilter = ({ categories }) => {
    return (
        <div className="messonry-button text-center mb-50">
            <Link className="is-checked" to={'/blog'}>About me</Link>
        </div>
    );
};

PortfolioFilter.propTypes = {
    categories: PropTypes.array,
};

export default PortfolioFilter;
